// import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import vehiclesJson from "../mockData/vehicles.json";

import {
  searchingFunc,
  filterFunc,
  isChecked,
} from "@/core/helpers/searchAndFilter";
export interface EVExplorerInfo {
  vehicleDetails: unknown;
  conditionOfVehicles: unknown;
  customer: Customer;
  allCustomer: Array<Customer>;
  selectedHeader: Array<Header>;
  allColumns: Array<Header>;
  searchCustomers: Array<Customer>;
  selectedCustomer: unknown;
  selectedTicket: unknown;
}
export interface Customer {
  vinNo: string;
  customerDetails: object;
  status: object;
  dealer: string;
  batteryVendor: string;
  maintenanceDue: string;
  location: string;
  dealerAddress: string;
  battery: object;
  evCommissionDate: string;
  evManufactureDate: string;
  evWarrentyStartDate: string;
}
export interface Header {
  columnName: string;
  columnLabel: string;
  sortEnabled: boolean;
  columnWidth: number;
}

const customerObj = {
  customerDetails: {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    mobileNo: "",
  },
  status: {
    type: "Active",
    color: "primary",
  },
  vinNo: "",
  dealer: "Laxmi Motors",
  batteryVendor: "Exide Batteries",
  maintenanceDue: "Sep 9, 2022",
  location: "",
  dealerAddress: "",
  battery: {
    id: "BAT0001",
    type: "Alkaline",
    chemistry: "Lithium-Ion",
  },
  evCommissionDate: "",
  evManufactureDate: "",
  evWarrentyStartDate: "",
};
@Module
export default class EVExplorerModule
  extends VuexModule
  implements EVExplorerInfo
{
  selectedTicket: unknown;
  selectedCustomer = {};
  public customer: Customer = customerObj;
  public searchCustomers: Array<Customer> = [];
  public allCustomer: Array<Customer> = vehiclesJson.vehicleData;
  public allColumns: Array<Header> = [
    {
      columnName: "Select All",
      columnLabel: "selectAll",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "VIN No.", //name
      columnLabel: "vinNo", //id
      sortEnabled: true,
      columnWidth: 175,
      // order:1
    },
    {
      columnName: "Customer Details",
      columnLabel: "customerDetails",
      sortEnabled: true,
      columnWidth: 250,
    },
    {
      columnName: "Dealer",
      columnLabel: "dealer",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Vendor",
      columnLabel: "batteryVendor",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Status",
      columnLabel: "status",
      sortEnabled: true,
      columnWidth: 100,
    },
    {
      columnName: "Maintenance Due",
      columnLabel: "maintenanceDue",
      sortEnabled: true,
      columnWidth: 115,
    },
    {
      columnName: "Customer Address",
      columnLabel: "customerAddress",
      sortEnabled: true,
      columnWidth: 225,
    },
    {
      columnName: "Location",
      columnLabel: "location",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Dealer Address",
      columnLabel: "dealerAddress",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery ID",
      columnLabel: "batteryId",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Type",
      columnLabel: "batteryType",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Chemistry",
      columnLabel: "batteryChemistry",
      sortEnabled: true,
      columnWidth: 100,
    },
    {
      columnName: "Date of EV Commission",
      columnLabel: "dateOfEVCommission",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "Date of EV Manufacture",
      columnLabel: "dateOfEVManufacture",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "EV Warrenty Start Date",
      columnLabel: "evWarrentyStartDate",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "Actions",
      columnLabel: "actions",
      sortEnabled: false,
      columnWidth: 175,
    },
  ];
  public selectedHeader: Array<Header> = [
    {
      columnName: "VIN No.",
      columnLabel: "vinNo",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Customer Details",
      columnLabel: "customerDetails",
      sortEnabled: true,
      columnWidth: 250,
    },
    {
      columnName: "Dealer",
      columnLabel: "dealer",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Vendor",
      columnLabel: "batteryVendor",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Status",
      columnLabel: "status",
      sortEnabled: true,
      columnWidth: 100,
    },
    {
      columnName: "Maintenance Due",
      columnLabel: "maintenanceDue",
      sortEnabled: true,
      columnWidth: 115,
    },
    {
      columnName: "Actions",
      columnLabel: "actions",
      sortEnabled: false,
      columnWidth: 175,
    },
  ];
  conditionOfVehicles = {};
  vehicleDetails = {};

  get getVehicleDetail() {
    return { vehicleDetails: this.vehicleDetails };
  }
  get getConditionOfVehicle() {
    return { conditionOfVehicles: this.conditionOfVehicles };
  }
  get getAllCustomers() {
    return { allCustomers: this.allCustomer };
  }
  get getSelectedHeaders() {
    return { selectedHeader: this.selectedHeader };
  }
  get getAllColumns() {
    return { allColumns: this.allColumns };
  }
  get getSearchCustomers() {
    return { searchCustomers: this.searchCustomers };
  }
  get getCustomer() {
    return { customer: this.selectedCustomer };
  }

  @Mutation
  [Mutations.SET_ALL_CUSTOMERS](allCustomer) {
    this.allCustomer = allCustomer;
  }
  @Mutation
  [Mutations.SET_ALL_HEADERS](selectedHeader) {
    this.selectedHeader = selectedHeader;
  }
  @Mutation
  [Mutations.SET_SEARCH_CUSTOMERS](allCustomer) {
    this.searchCustomers = allCustomer;
  }
  @Mutation
  [Mutations.REMOVE_CUSTOMER](id) {
    this.allCustomer = JSON.parse(JSON.stringify(this.allCustomer));
    this.allCustomer = JSON.parse(
      JSON.stringify(
        this.allCustomer.filter((customer) => customer.vinNo !== id)
      )
    );
  }
  @Mutation
  [Mutations.SET_VIEW_CUSTOMER](id) {
    this.allCustomer = JSON.parse(JSON.stringify(this.allCustomer));
    this.selectedCustomer = JSON.parse(
      JSON.stringify(this.allCustomer.find((customer) => customer.vinNo === id))
    );
  }
  @Mutation
  [Mutations.SET_VIEW_TICKET](id) {
    this.selectedTicket = JSON.parse(id);
  }
  @Mutation
  [Mutations.ADD_NEW_CUSTOMER](data: Array<Customer>) {
    this.allCustomer = JSON.parse(JSON.stringify(this.allCustomer));
    this.allCustomer.push(JSON.parse(JSON.stringify(data)));
    this.allCustomer = JSON.parse(JSON.stringify(this.allCustomer));
  }

  @Mutation
  [Mutations.SET_SUMMARY_DATA](data) {
    this.vehicleDetails = { ...data.vehicleDetails };
    this.conditionOfVehicles = { ...data.conditionOfVehicles };
  }
  @Mutation
  [Mutations.SET_NEW_HEADER](data) {
    data = JSON.parse(JSON.stringify(data));
    this.selectedHeader = JSON.parse(JSON.stringify(this.selectedHeader));
    if (data.columnLabel === "selectAll" && data.checkOrNot) {
      this.selectedHeader = JSON.parse(JSON.stringify(this.allColumns)).slice(
        1
      );
    } else {
      if (
        data.checkOrNot &&
        !isChecked(JSON.parse(JSON.stringify(this.selectedHeader)), data)
      ) {
        this.selectedHeader.splice(this.selectedHeader.length - 1, 0, data);
        this.selectedHeader = JSON.parse(JSON.stringify(this.selectedHeader));
      } else {
        if (isChecked(JSON.parse(JSON.stringify(this.selectedHeader)), data)) {
          this.selectedHeader = this.selectedHeader.filter(
            (header) => header.columnLabel != data.columnLabel
          );
          this.selectedHeader = JSON.parse(JSON.stringify(this.selectedHeader));
        }
      }
    }
  }

  @Action
  [Actions.ALL_CUSTOMERS](data = this.allCustomer) {
    this.context.commit(Mutations.SET_ALL_CUSTOMERS, data);
  }
  @Action
  [Actions.ALL_HEADERS](data = this.selectedHeader) {
    this.context.commit(Mutations.SET_ALL_HEADERS, data);
  }
  @Action
  [Actions.ADD_CUSTOMER](payload) {
    this.context.commit(Mutations.ADD_NEW_CUSTOMER, payload);
  }
  @Action
  [Actions.VIEW_CUSTOMER](id) {
    this.context.commit(Mutations.SET_VIEW_CUSTOMER, id);
  }
  @Action
  [Actions.VIEW_TICKET](id) {
    this.context.commit(Mutations.SET_VIEW_TICKET, id);
  }
  @Action
  [Actions.DELETE_CUSTOMER](id) {
    this.context.commit(Mutations.REMOVE_CUSTOMER, id);
  }
  @Action
  [Actions.SEARCH_ALL_CUSTOMERS](value = "") {
    this.context.commit(Mutations.SET_SEARCH_CUSTOMERS, []);
    if (value) {
      for (let j = 0; j < this.allCustomer.length; j++) {
        if (searchingFunc(this.allCustomer[j], value)) {
          this.searchCustomers.push(this.allCustomer[j]);
        }
      }
      this.context.commit(Mutations.SET_SEARCH_CUSTOMERS, this.searchCustomers);
    }
  }
  @Action
  [Actions.FILTER_BY_TYPE](data) {
    this.context.commit(Mutations.SET_SEARCH_CUSTOMERS, []);
    for (let j = 0; j < this.allCustomer.length; j++) {
      if (filterFunc(this.allCustomer[j], data)) {
        this.searchCustomers.push(this.allCustomer[j]);
      }
    }
    this.context.commit(Mutations.SET_SEARCH_CUSTOMERS, this.searchCustomers);
  }
  @Action
  [Actions.ADD_NEW_HEADER](data) {
    this.context.commit(Mutations.SET_NEW_HEADER, data);
  }

  @Action
  [Actions.SUMMARY_DATA]() {
    const vehicleDetails = [
      {
        name: "All Vehicle",
        value: 38,
        svg: "abs043",
        color: "primary",
      },
      {
        name: "Active",
        value: 35,
        svg: "abs045",
        color: "info",
      },
      {
        name: "In Active",
        value: 2,
        svg: "abs044",
        color: "danger",
      },
    ];
    const conditionOfVehicles = [
      {
        name: "Requests resolved",
        value: 23,
        svg: "abs043",
        color: "info",
      },
      {
        name: "In progress",
        value: 3,
        svg: "abs044",
        color: "primary",
      },
      {
        name: "Work done",
        value: 5,
        svg: "abs045",
        color: "info",
      },
    ];
    this.context.commit(Mutations.SET_SUMMARY_DATA, {
      vehicleDetails,
      conditionOfVehicles,
    });
  }
}
