const config = {
  clientName: "DP Pulse",
  imagePath: "media/datakrew/dp-pulse/dpPulse-logo.png",
  svgPath: "media/datakrew/dp-pulse/dpPulse-logo.png",
  logoSVGPath: "media/datakrew/dp-pulse/dpPulse-logo.png",
  faviconIcon: "media/datakrew/dp-pulse/dp-logo.png",
  pages: {
    signIn: {
      componentName: "DPPulseSignIn",
      componentPath: "views/domains/dp-pulse/DPPulseSignIn",
      email: "admin@dppulse.com",
      password: "dppulse",
    },
  },
};

export default config;
