import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import EVExplorerModule from "@/store/modules/EVExplorerModule";
import BatteryExplorerModule from "@/store/modules/BatteryExplorerModule";
import DataverseModule from "@/store/modules/DataverseModule";
import DomainModule from "@/store/modules/DomainModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    EVExplorerModule,
    BatteryExplorerModule,
    DataverseModule,
    DomainModule,
  },
});

export default store;
