import oxredObj from "./clients/oxred";
import dpPulseObj from "./clients/dp-pulse";

// const config = {
//   "localhost:8080": oxredObj,
//   "localhost:8081": dpPulseObj,
// };

const config = {
  "oxred.netlify.app": oxredObj,
  "oxred.co": oxredObj,
  "dp-pulse.netlify.app": dpPulseObj,
};

export default config;
