const searchingFunc = (obj, value): boolean => {
  for (const key in obj) {
    if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
      if (obj[key].indexOf(value) != -1) {
        return true;
      }
    }
  }
  return false;
};

const filterFunc = (obj, data): boolean => {
  for (const key in obj) {
    if (
      key === data.type &&
      !Number.isInteger(obj[key]) &&
      !(typeof obj[key] === "object")
    ) {
      if (obj[key] === data.value) {
        return true;
      }
    }
  }
  return false;
};
const isChecked = (array: object[], object: object) => {
  return array.some((item) => item["columnLabel"] === object["columnLabel"]);
};
export { searchingFunc, filterFunc, isChecked };
