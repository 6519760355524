// import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class domainModule extends VuexModule {
  domain = "";
  tabName = "";

  get getDomain() {
    return this.domain;
  }
  get getTabName() {
    return this.tabName;
  }
  @Mutation
  [Mutations.SET_DOMAIN_ID](id) {
    this.domain = id;
  }
  @Mutation
  [Mutations.SET_TAB_NAME](id) {
    this.tabName = id;
  }

  @Action
  [Actions.SET_DOMAIN](id) {
    this.context.commit(Mutations.SET_DOMAIN_ID, id);
  }
  @Action
  [Actions.SET_TAB](tabName) {
    this.context.commit(Mutations.SET_TAB_NAME, tabName);
  }
}
