// import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import json from "../DataverseMockData/SOCPlot.json";

@Module
export default class dataverseModule extends VuexModule {
  searchId = "VIN00001";
  get getSearchId() {
    return { searchId: this.searchId };
  }
  get SOCData() {
    return { json: json };
  }
  @Mutation
  [Mutations.SET_SEARCH_DATAVERSE_ID](id) {
    this.searchId = id;
  }

  @Action
  [Actions.SEARCH_DATAVERSE_ID](id) {
    console.log(id);
    this.context.commit(Mutations.SET_SEARCH_DATAVERSE_ID, id);
  }
}
