// import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import batteryJson from "../mockData/batteries.json";

import {
  searchingFunc,
  filterFunc,
  isChecked,
} from "@/core/helpers/searchAndFilter";
export interface BatteryExplorerInfo {
  batteryDetails: unknown;
  issueSummary: unknown;
  battery: Battery;
  allBattery: Array<Battery>;
  searchBatteries: Array<Battery>;
  selectedBatteryHeader: Array<Header>;
  allBatteryColumns: Array<Header>;
}
export interface Battery {
  batteryId: string;
  vinNo: string;
  evMaker: string;
  customerDetails: object;
  status: object;
  dealer: string;
  maintenanceDue: string;
  battery: object;
  evCommissionDate: string;
  evManufactureDate: string;
  warrentyStartDate: string;
  warrentyEndDate: string;
  location: string;
  dealerAddress: string;
}
export interface Header {
  columnName: string;
  columnLabel: string;
  sortEnabled: boolean;
  columnWidth: number;
}
const customerObj = {
  batteryId: "",
  vinNo: "",
  evMaker: "Laxmi Motors",
  customerDetails: {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    mobileNo: "",
  },
  status: {
    type: "Active",
    color: "success",
  },
  dealer: "Laxmi Motors",
  maintenanceDue: "Sep 9, 2022",
  dealerAddress: "",
  battery: {
    type: "Alkaline",
    chemistry: "Lithium-Ion",
    parameters: "Mass energy Density, Volume energy Density",
    faults: "Low voltage fault",
  },
  evCommissionDate: "",
  evManufactureDate: "",
  warrentyStartDate: "",
  warrentyEndDate: "",
  location: "",
};
@Module
export default class BatteryExplorerModule
  extends VuexModule
  implements BatteryExplorerInfo
{
  selectedBattery = {};
  public battery: Battery = customerObj;
  public searchBatteries: Array<Battery> = [];
  public allBattery: Array<Battery> = batteryJson.batteryData;
  public allBatteryColumns: Array<Header> = [
    {
      columnName: "Select All",
      columnLabel: "selectAll",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery ID",
      columnLabel: "batteryId",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Customer Details",
      columnLabel: "customerDetails",
      sortEnabled: true,
      columnWidth: 250,
    },
    {
      columnName: "Dealer",
      columnLabel: "dealer",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Status",
      columnLabel: "status",
      sortEnabled: true,
      columnWidth: 75,
    },
    {
      columnName: "Maintenance Due",
      columnLabel: "maintenanceDue",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Type",
      columnLabel: "batteryType",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Parameters",
      columnLabel: "batteryParameters",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Faults",
      columnLabel: "batteryFaults",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Battery Chemistry",
      columnLabel: "batteryChemistry",
      sortEnabled: true,
      columnWidth: 100,
    },
    {
      columnName: "VIN No.",
      columnLabel: "vinNo",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "EV Maker(Buyer)",
      columnLabel: "evMaker",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Date of EV Commission",
      columnLabel: "dateOfEVCommission",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "Date of EV Manufacture",
      columnLabel: "dateOfEVManufacture",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "Warrenty Start Date",
      columnLabel: "warrentyStartDate",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "Warrenty End Date",
      columnLabel: "warrentyEndDate",
      sortEnabled: true,
      columnWidth: 200,
    },
    {
      columnName: "Actions",
      columnLabel: "actions",
      sortEnabled: false,
      columnWidth: 175,
    },
  ];

  public selectedBatteryHeader: Array<Header> = [
    {
      columnName: "Battery ID",
      columnLabel: "batteryId",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Customer Details",
      columnLabel: "customerDetails",
      sortEnabled: true,
      columnWidth: 250,
    },
    {
      columnName: "Dealer",
      columnLabel: "dealer",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "EV Maker",
      columnLabel: "evMaker",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Status",
      columnLabel: "status",
      sortEnabled: true,
      columnWidth: 75,
    },
    {
      columnName: "Maintenance Due",
      columnLabel: "maintenanceDue",
      sortEnabled: true,
      columnWidth: 175,
    },
    {
      columnName: "Actions",
      columnLabel: "actions",
      sortEnabled: false,
      columnWidth: 175,
    },
  ];
  issueSummary = {};
  batteryDetails = {};

  get getBatteryDetails() {
    return { batteryDetails: this.batteryDetails };
  }
  get getIssueSummary() {
    return { issueSummary: this.issueSummary };
  }
  get getAllBatteries() {
    return { allBatteries: this.allBattery };
  }
  get getSearchBatteries() {
    return { searchBatteries: this.searchBatteries };
  }
  get getBattery() {
    return { battery: this.selectedBattery };
  }
  get getSelectedBatteryHeaders() {
    return { selectedBatteryHeader: this.selectedBatteryHeader };
  }
  get getAllBatteryColumns() {
    return { allBatteryColumns: this.allBatteryColumns };
  }
  @Mutation
  [Mutations.SET_ALL_BATTERIES](allBattery) {
    this.allBattery = allBattery;
  }
  @Mutation
  [Mutations.SET_SEARCH_BATTERIES](allBattery) {
    this.searchBatteries = allBattery;
  }
  @Mutation
  [Mutations.REMOVE_BATTERY](id) {
    this.allBattery = JSON.parse(JSON.stringify(this.allBattery));
    this.allBattery = JSON.parse(
      JSON.stringify(
        this.allBattery.filter((battery) => battery.batteryId !== id)
      )
    );
  }
  @Mutation
  [Mutations.SET_ALL_BATTERY_HEADERS](selectedBatteryHeader) {
    this.selectedBatteryHeader = selectedBatteryHeader;
  }
  @Mutation
  [Mutations.SET_NEW_BATTERY_HEADER](data) {
    data = JSON.parse(JSON.stringify(data));
    this.selectedBatteryHeader = JSON.parse(
      JSON.stringify(this.selectedBatteryHeader)
    );
    if (data.columnLabel === "selectAll" && data.checkOrNot) {
      this.selectedBatteryHeader = JSON.parse(
        JSON.stringify(this.allBatteryColumns)
      ).slice(1);
    } else {
      if (
        data.checkOrNot &&
        !isChecked(JSON.parse(JSON.stringify(this.selectedBatteryHeader)), data)
      ) {
        this.selectedBatteryHeader.splice(
          this.selectedBatteryHeader.length - 1,
          0,
          data
        );
        this.selectedBatteryHeader = JSON.parse(
          JSON.stringify(this.selectedBatteryHeader)
        );
      } else {
        if (
          isChecked(
            JSON.parse(JSON.stringify(this.selectedBatteryHeader)),
            data
          )
        ) {
          this.selectedBatteryHeader = this.selectedBatteryHeader.filter(
            (header) => header.columnLabel != data.columnLabel
          );
          this.selectedBatteryHeader = JSON.parse(
            JSON.stringify(this.selectedBatteryHeader)
          );
        }
      }
    }
  }
  @Mutation
  [Mutations.SET_VIEW_BATTERY](id) {
    this.allBattery = JSON.parse(JSON.stringify(this.allBattery));
    this.selectedBattery = JSON.parse(
      JSON.stringify(
        this.allBattery.find((battery) => battery.batteryId === id)
      )
    );
  }
  @Mutation
  [Mutations.ADD_NEW_BATTERY](data: Array<Battery>) {
    this.allBattery = JSON.parse(JSON.stringify(this.allBattery));
    this.allBattery.push(JSON.parse(JSON.stringify(data)));
    this.allBattery = JSON.parse(JSON.stringify(this.allBattery));
  }
  @Mutation
  [Mutations.SET_BATTERY_SUMMARY_DATA](data) {
    this.batteryDetails = { ...data.batteryDetails };
    this.issueSummary = { ...data.issueSummary };
  }

  @Action
  [Actions.ALL_BATTERIES](data = this.allBattery) {
    this.context.commit(Mutations.SET_ALL_BATTERIES, data);
  }
  @Action
  [Actions.ALL_BATTERY_HEADERS](data = this.selectedBatteryHeader) {
    this.context.commit(Mutations.SET_ALL_BATTERY_HEADERS, data);
  }
  @Action
  [Actions.ADD_NEW_BATTERY_HEADER](data) {
    this.context.commit(Mutations.SET_NEW_BATTERY_HEADER, data);
  }
  @Action
  [Actions.ADD_BATTERY](payload) {
    this.context.commit(Mutations.ADD_NEW_BATTERY, payload);
  }
  @Action
  [Actions.VIEW_BATTERY](id) {
    this.context.commit(Mutations.SET_VIEW_BATTERY, id);
  }
  @Action
  [Actions.DELETE_BATTERY](id) {
    this.context.commit(Mutations.REMOVE_BATTERY, id);
  }
  @Action
  [Actions.SEARCH_ALL_BATTERIES](value = "") {
    this.context.commit(Mutations.SET_SEARCH_BATTERIES, []);
    if (value) {
      for (let j = 0; j < this.allBattery.length; j++) {
        if (searchingFunc(this.allBattery[j], value)) {
          this.searchBatteries.push(this.allBattery[j]);
        }
      }
      this.context.commit(Mutations.SET_SEARCH_BATTERIES, this.searchBatteries);
    }
  }
  @Action
  [Actions.FILTER_BY_TYPE](data) {
    this.context.commit(Mutations.SET_SEARCH_CUSTOMERS, []);
    for (let j = 0; j < this.allBattery.length; j++) {
      if (filterFunc(this.allBattery[j], data)) {
        this.searchBatteries.push(this.allBattery[j]);
      }
    }
    this.context.commit(Mutations.SET_SEARCH_CUSTOMERS, this.searchBatteries);
  }
  @Action
  [Actions.BATTERY_SUMMARY_DATA]() {
    const batteryDetails = [
      {
        name: "All batteries",
        value: 38,
        svg: "abs044",
        color: "primary",
      },
      {
        name: "Active",
        value: 37,
        svg: "abs045",
        color: "info",
      },
      {
        name: "In Active",
        value: 1,
        svg: "abs043",
        color: "danger",
      },
    ];
    const issueSummary = [
      {
        name: "Requests resolved",
        value: 20,
        svg: "abs043",
        color: "info",
      },
      {
        name: "In progress",
        value: 15,
        svg: "abs044",
        color: "secondary",
      },
      {
        name: "Work done",
        value: 4,
        svg: "abs045",
        color: "info",
      },
    ];
    this.context.commit(Mutations.SET_BATTERY_SUMMARY_DATA, {
      batteryDetails,
      issueSummary,
    });
    // return ApiService.get("summaryData")
    //   .then(({ data }) => {
    //     this.context.commit(Mutations.SET_AUTH, data);
    //   })
    //   .catch(({ response }) => {
    //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //   });
  }
}
