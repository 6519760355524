enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  SUMMARY_DATA = "summaryData",
  ALL_CUSTOMERS = "allCustomers",
  ADD_CUSTOMER = "addCustomer",
  VIEW_CUSTOMER = "viewCustomer",
  DELETE_CUSTOMER = "deleteCustomer",
  SEARCH_ALL_CUSTOMERS = "searchAllCustomers",
  ALL_BATTERIES = "allBatteries",
  ADD_BATTERY = "addBattery",
  VIEW_BATTERY = "viewBattery",
  DELETE_BATTERY = "deleteBattery",
  SEARCH_ALL_BATTERIES = "searchAllBatteries",
  BATTERY_SUMMARY_DATA = "batterySummaryData",
  FILTER_BY_TYPE = "filterByType",
  ALL_HEADERS = "allHeaders",
  ADD_NEW_HEADER = "addNewHeader",
  ALL_BATTERY_HEADERS = "allbatteryHeaders",
  ADD_NEW_BATTERY_HEADER = "addNewBatteryHeader",
  SEARCH_DATAVERSE_ID = "searchDataverseId",
  SET_DOMAIN = "setDomain",
  VIEW_TICKET = "viewTicket",
  SET_TAB = "setTab",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_SUMMARY_DATA = "setSummaryData",
  SET_ALL_CUSTOMERS = "setAllCustomers",
  ADD_NEW_CUSTOMER = "addNewCustomer",
  SET_VIEW_CUSTOMER = "setViewCustomer",
  REMOVE_CUSTOMER = "removeCustomer",
  SET_SEARCH_CUSTOMERS = "setSearchCustomers",
  SET_ALL_BATTERIES = "setAllBatteries",
  SET_SEARCH_BATTERIES = "setSearchBatteries",
  REMOVE_BATTERY = "removeBattery",
  SET_VIEW_BATTERY = "setViewBattery",
  ADD_NEW_BATTERY = "addNewBattery",
  SET_BATTERY_SUMMARY_DATA = "setBattertSummaryData",
  SET_ALL_HEADERS = "setAllHeaders",
  SET_NEW_HEADER = "setNewHeader",
  SET_ALL_BATTERY_HEADERS = "setAllBatteryHeaders",
  SET_NEW_BATTERY_HEADER = "setNewBatteryHeader",
  SET_SEARCH_DATAVERSE_ID = "setSearchDataverseId",
  SET_DOMAIN_ID = "setDomainId",
  SET_VIEW_TICKET = "setViewTicket",
  SET_TAB_NAME = "setTabName",
}

export { Actions, Mutations };
